@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");

html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: url(/public/bg.jpg);
    background-size: cover;
}

#root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
}
